import styled from 'styled-components';

const SmallRowWrapper = styled.div`
  margin: 10px 0;

  @media (min-width: 768px) {
    display: flex;
  }
`;

export default SmallRowWrapper;
