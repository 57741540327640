import styled from 'styled-components';

const SmallColumnWrapper = styled.div`
  @media (min-width: 768px) {
    flex: 1;
    width: 50%;

    &:first-child {
      padding-right: 15px;
    }

    &:last-child {
      padding-left: 15px;
    }
  }
`;

export default SmallColumnWrapper;
