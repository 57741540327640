import styled from 'styled-components';

const LargeColumnWrapper = styled.div`
  @media (min-width: 576px) {
    flex: 1;
    width: 50%;

    &:first-child {
      border-right: 1px solid #ccc;
      padding-right: 20px;
    }

    &:last-child {
      padding-left: 20px;
    }
  }

  img {
    max-width: 100%;
  }
`;

export default LargeColumnWrapper;
