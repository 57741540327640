import { Fragment } from 'react';
import { Link } from 'gatsby';

import LargeColumnWrapper from './LargeColumnWrapper';
import SmallColumnWrapper from './SmallColumnWrapper';
import SmallRowWrapper from './SmallRowWrapper';

const ScottishAndBritish = () => (
  <div>
    <p>
      <Link to="/articles/everyones-flag">
        <img
          src="/media/articles/everyones-flag/scottish-and-british.png"
          alt="Scottish & British"
          style={{ width: '100%' }}
        />
      </Link>
    </p>
  </div>
);

const LatestArticle = () => (
  <div
    style={{
      display: 'flex',
      'align-items': 'center',
      'flex-direction': 'column'
    }}
  >
    <div>
      <h3>LAMENT FOR THE UNHEARD</h3>

      <p>
        All your compassion,
        <br />
        and progressive talk,
        <br />
        means little or nothing to us
        <br />
        when we are not heard
        <br />
        ...
        <Link to="/articles/lament-for-the-unheard" data-qa="read-more-link">
          Read more
        </Link>
      </p>
    </div>
  </div>
);

const LettersToTheEditor = () => (
  <Fragment>
    <p>
      <strong>Letters to the Editor…</strong>
    </p>

    <p>
      <Link to="/letters-to-the-editor/">
        <img
          src="/media/letters-to-the-editor/lead-image/letters-to-the-editor-march-2020.png"
          alt="Letters to the Editor"
          style={{ width: '100%' }}
        />
      </Link>
    </p>

    <p>
      I have now stopped regular letter writing – but here are archived copies
      of letters published in the press over recent years on a wide range of
      topics linked to the independence debate...
      <Link to="/letters-to-the-editor/" data-qa="read-more-link">
        Read more
      </Link>
    </p>
  </Fragment>
);

const HomeRightColumn = () => (
  <LargeColumnWrapper data-qa="homepage-right-column">
    <ScottishAndBritish />

    <hr />

    <LatestArticle />

    <hr />

    <SmallRowWrapper>
      <SmallColumnWrapper data-qa="letters-to-the-editor-panel">
        <LettersToTheEditor />
      </SmallColumnWrapper>
    </SmallRowWrapper>
  </LargeColumnWrapper>
);

export default HomeRightColumn;
